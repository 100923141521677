type FileValidation = {
  maxSize: number;
  allowedTypes: string[];
};

const FileValidations: FileValidation = {
  maxSize: 4096000,
  allowedTypes: [
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "application/excel",
    "application/vnd.ms-excel",
    "application/x-excel",
    "application/x-msexcel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/mspowerpoint",
    "application/powerpoint",
    "application/vnd.ms-powerpoint",
    "application/x-mspowerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/pdf",
    "application/x-pdf",
    "text/plain",
  ],
};

export { FileValidations };
