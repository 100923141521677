<template>
  <telia-grid class="information-wrapper">
    <telia-row>
      <telia-col width="12">
        <telia-heading tag="h1" variant="title-100">
          {{ t("ORDER_INFO.DETAILS_SECTION.TITLE") }}
        </telia-heading>
      </telia-col>
    </telia-row>

    <telia-row>
      <telia-col v-for="item in mappedOrderFields" :key="item.id" width="6">
        <telia-heading tag="h3" variant="subsection-100">{{ item.label }}</telia-heading>
        <telia-p>{{ item.value }}</telia-p>
      </telia-col>
    </telia-row>
  </telia-grid>
</template>

<script>
import { translateMixin } from "../../locale";
import { getMappedFields } from "./orderDetails";

export default {
  name: "order-details-information",
  mixins: [translateMixin],
  props: {
    order: {
      type: Object,
    },
  },
  computed: {
    mappedOrderFields() {
      return getMappedFields(this.order);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

telia-grid.information-wrapper {
  padding: 0;
  telia-row > telia-col {
    padding-bottom: $telia-spacing-16;
  }
}
</style>
