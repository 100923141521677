import { TableCellType } from "@telia/b2x-table/src/components/table-cell/enums/table-cell-type";
import { TableManagerColumn } from "@telia/b2x-table/dist/types/components/table-column-manager/table-column-manager";
import { translate, currentLanguage } from "@telia/b2b-i18n";

type OrderType = "CANCELLATION" | "CHANGE" | "INPORTING" | "MOVE" | "NEW" | "TRANSFER" | "UNKNOWN";
const orderTypes: OrderType[] = [
  "CANCELLATION",
  "CHANGE",
  "INPORTING",
  "MOVE",
  "NEW",
  "TRANSFER",
  "UNKNOWN",
];

type OrderStatus =
  | "RECEIVED"
  | "ONGOING"
  | "UNKNOWN"
  | "CANCELLED"
  | "CLOSED"
  | "DELIVERED"
  | "INCOMPLETE"
  | "RESOLVED"
  | "AWAITING_INFO"
  | "OUT_OF_STOCK"
  | "WAITING_FOR_SIGNING_AGREEMENTS";

const orderStatuses: OrderStatus[] = [
  "ONGOING",
  "INCOMPLETE",
  "RECEIVED",
  "CANCELLED",
  "DELIVERED",
];

const ongoingOrderStatuses: OrderStatus[] = [
  "ONGOING",
  "INCOMPLETE",
];

const receivedOrderStatuses: OrderStatus[] = [
  "RECEIVED",
];

const completedOrderStatuses: OrderStatus[] = [
  "CANCELLED",
  "DELIVERED",
];

export type TranslatedOrderType = {
  id: OrderType;
  translatedName: string;
};

export const getTranslatedAndSortedStatuses = (columnName: string, sectionType: string): TranslatedOrderType[] => {
  let typeArray;

  if (columnName === "TYPE") {
    typeArray = orderTypes;
  } else if (columnName === "ORDER_STATUS") {
    if (sectionType === "ONGOING") {
      typeArray = ongoingOrderStatuses;
    } else if (sectionType === "RECEIVED") {
      typeArray = receivedOrderStatuses;
    } else if (sectionType === "COMPLETED") {
      typeArray = completedOrderStatuses;
    } else {
      typeArray = orderStatuses;
    }
  } else {
    typeArray = orderStatuses;
  }

  return typeArray
    .map((key) => {
      return {
        id: key,
        translatedName: translate(`${columnName}.${key}`) ?? "",
      };
    })
    .sort((status1: TranslatedOrderType, status2: TranslatedOrderType): number => {
      // Place "unknown" status LAST, sort all the others alphabetically
      if (status1.id === "UNKNOWN" && status2.id === "UNKNOWN") {
        return 0;
      } else if (status1.id === "UNKNOWN" && status2.id !== "UNKNOWN") {
        return 1;
      } else if (status1.id !== "UNKNOWN" && status2.id === "UNKNOWN") {
        return -1;
      } else {
        return status1.translatedName.localeCompare(status2.translatedName, currentLanguage(), {
          sensitivity: "base",
        });
      }
    });
};

export const getOrderTableColumns = (sectionType: string): Record<string, TableManagerColumn> => ({
  orderStatus: {
    title: "ORDER_STATUS",
    type: TableCellType.STATUS_BADGE,
    isChecked: true,
    disabled: false,
    filterable: {
      type: "enum",
      label: translate("COLUMN.ORDER_STATUS"),
      values: getTranslatedAndSortedStatuses("ORDER_STATUS", sectionType).map((orderType) => ({
        value: orderType.id,
        displayName: orderType.translatedName,
      })),
      noSelectionDisplayName: translate("COLUMN.ORDER_STATUS_ALL"),
    },
  },
  orderNumber: {
    title: "ORDER_NUMBER",
    type: TableCellType.BUTTON_TEXT,
    isChecked: true,
    disabled: false,
    filterable: { type: "text", label: translate("COLUMN.ORDER_NUMBER") },
  },
  description: {
    title: "DESCRIPTION",
    type: TableCellType.TRUNCATABLE_TEXT,
    isChecked: true,
    disabled: false,
    filterable: { type: "text", label: translate("COLUMN.DESCRIPTION") },
  },
  type: {
    title: "TYPE",
    type: TableCellType.TEXT,
    isChecked: true,
    disabled: false,
    filterable: {
      type: "enum",
      label: translate("COLUMN.TYPE"),
      values: getTranslatedAndSortedStatuses("TYPE", sectionType).map((orderType) => ({
        value: orderType.id,
        displayName: orderType.translatedName,
      })),
      noSelectionDisplayName: translate("COLUMN.TYPE_ALL"),
    },
  },
  promisedDeliveryDate: {
    title: "PROMISED_DELIVERY_DATE",
    type: TableCellType.TEXT,
    isChecked: true,
    disabled: false,
    filterable: { type: "text", label: translate("COLUMN.PROMISED_DELIVERY_DATE") },
  },
  registeredDate: {
    title: "REGISTERED_DATE",
    type: TableCellType.TEXT,
    isChecked: true,
    disabled: false,
    filterable: { type: "text", label: translate("COLUMN.REGISTERED_DATE") },
  },
  organisationName: {
    title: "ORGANISATION_NAME",
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    filterable: { type: "text", label: translate("COLUMN.ORGANISATION_NAME") },
  },
  orderedBy: {
    title: "ORDERED_BY",
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    filterable: { type: "text", label: translate("COLUMN.ORDERED_BY") },
  },
  reference: {
    title: "REFERENCE",
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    filterable: { type: "text", label: translate("COLUMN.REFERENCE") },
  },
  updatedDate: {
    title: "UPDATED_DATE",
    type: TableCellType.TEXT,
    isChecked: true,
    disabled: false,
    filterable: { type: "text", label: translate("COLUMN.UPDATED_DATE") },
  },
  completionDate: {
    title: "COMPLETION_DATE",
    type: TableCellType.TEXT,
    isChecked: true,
    disabled: false,
    filterable: { type: "text", label: translate("COLUMN.COMPLETION_DATE") },
  },
});

function getReceivedColumns(): TableManagerColumn[] {
  const {
    orderStatus,
    orderNumber,
    description,
    type,
    registeredDate,
    organisationName,
    orderedBy,
    reference,
  } = getOrderTableColumns("RECEIVED");
  return [
    orderStatus,
    orderNumber,
    description,
    type,
    registeredDate,
    organisationName,
    orderedBy,
    reference,
  ];
}

function getOngoingColumns(): TableManagerColumn[] {
  const {
    orderStatus,
    orderNumber,
    description,
    type,
    promisedDeliveryDate,
    registeredDate,
    organisationName,
    orderedBy,
    reference,
    updatedDate,
  } = getOrderTableColumns("ONGOING");
  return [
    orderStatus,
    orderNumber,
    description,
    type,
    promisedDeliveryDate,
    registeredDate,
    organisationName,
    orderedBy,
    reference,
    updatedDate,
  ];
}
function getCompletedColumns(): TableManagerColumn[] {
  const {
    orderStatus,
    orderNumber,
    description,
    type,
    completionDate,
    registeredDate,
    organisationName,
    orderedBy,
    reference,
    updatedDate,
  } = getOrderTableColumns("COMPLETED");
  return [
    orderStatus,
    orderNumber,
    description,
    type,
    { ...completionDate, isChecked: false },
    registeredDate,
    organisationName,
    orderedBy,
    reference,
    updatedDate,
  ];
}

interface ColumnOrderProperty {
  REGISTERED_DATE: "registeredDate";
  COMPLETION_DATE: "deliveredDate";
  PROMISED_DELIVERY_DATE: "promisedDeliveryDate";
  DESCRIPTION: "description";
  ORGANISATION_NAME: "organisationName";
  REFERENCE: "reference";
  ORDER_NUMBER: "orderId";
  ORDER_STATUS: "orderStatus";
  ORDERED_BY: "orderedBy";
  TYPE: "orderType";
  UPDATED_DATE: "updatedDate";
}
const ColumnOrderProperty: ColumnOrderProperty = {
  REGISTERED_DATE: "registeredDate",
  COMPLETION_DATE: "deliveredDate",
  PROMISED_DELIVERY_DATE: "promisedDeliveryDate",
  DESCRIPTION: "description",
  ORGANISATION_NAME: "organisationName",
  REFERENCE: "reference",
  ORDER_NUMBER: "orderId",
  ORDER_STATUS: "orderStatus",
  ORDERED_BY: "orderedBy",
  TYPE: "orderType",
  UPDATED_DATE: "updatedDate",
};

export { getCompletedColumns, getOngoingColumns, getReceivedColumns, ColumnOrderProperty };
