export enum TableCellType {
  TEXT = "text",
  TRUNCATABLE_TEXT = "truncatable-text",
  TRUNCATABLE_FLEXIBLE_WIDTH_TEXT = "truncatable-flexible-width-text",
  LINK = "link",
  SSPA_LINK = "sspa-link",
  BUTTON = "button",
  BUTTON_TEXT = "button-text",
  BADGE = "badge",
  STATUS_BADGE = "status-badge",
  CHECKBOX = "checkbox",
  TOGGLE = "toggle",
  COLORED_TEXT_WITH_ICON = "colored-text-with-icon",
}
