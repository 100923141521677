<template>
  <section t-id="order-details" data-test-id="slide-in-panel" class="slide-in-panel-container">
    <section>
      <telia-skeleton class="skeleton skeleton--header" />
    </section>
    <section>
      <telia-skeleton class="skeleton skeleton--status" />
    </section>

    <section class="support">
      <telia-skeleton class="skeleton skeleton--support" />
    </section>

    <section class="product">
      <telia-skeleton class="skeleton skeleton--product" />
    </section>
    <section class="information">
      <telia-skeleton class="skeleton skeleton--title" />
      <telia-skeleton class="skeleton skeleton--information" />
    </section>
  </section>
</template>

<script>
export default {
  name: "order-details-skeleton",
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
.slide-in-panel-container {
  > *:not(.status) {
    padding-bottom: $telia-spacing-32;
  }
}
.skeleton {
  width: 100%;
  &--header {
    height: 3rem;
  }
  &--status {
    margin-top: $telia-spacing-96;
    height: 8rem;
  }
  &--support {
    width: 70%;
    margin: 0 auto;
    height: 6rem;
  }

  &--product {
    height: 15rem;
  }

  &--information {
    height: 16rem;
  }
  &--title {
    height: 3em;
    margin-bottom: $telia-spacing-16;
  }
}
</style>
