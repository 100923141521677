<template>
  <div class="support-case">
    <div class="order-header-wrapper" @click="expandToggle">
      <div class="description-wrapper">
        <div>
          <span>
            <telia-heading tag="h3" variant="title-100">
              {{ `${supportCase.caseId} - ${supportCase.shortDescription}` }}
            </telia-heading>
          </span>
        </div>
        <div class="support-status-wrapper">
          <span class="status" :class="supportCase.status" />
          <span>{{ t(`SUPPORT_STATUS.${supportCase.status}`) }}</span>
        </div>
      </div>
      <div class="toggle-icon">
        <telia-icon :name="expanded ? 'chevron-up' : 'chevron-down'" size="md" />
      </div>
    </div>
    <DetailsExpander>
      <div v-show="expanded" class="details-wrapper">
        <SupportCaseDetailsSkeleton v-if="loadingCaseDetails" />
        <SupportCaseDetails
          v-else
          :description="caseDetails.description"
          :reporterName="reporterFullName"
          :reporterEmail="caseDetails.reportingPerson.email"
          :reporterPhoneNumber="caseDetails.reportingPerson.number"
          :created="created"
          :lastChange="lastChange"
          :scopeId="scopeId"
          :caseId="caseDetails.caseId"
        >
        </SupportCaseDetails>
      </div>
    </DetailsExpander>
  </div>
</template>

<script>
import { translateMixin, currentLanguage } from "../../../locale";
import { corpSupportCase } from "@telia/b2x-rest-client";
import SupportCaseDetails from "./SupportCaseDetails.vue";
import SupportCaseDetailsSkeleton from "./SupportCaseDetailsSkeleton.vue";
import DetailsExpander from "../DetailsExpander.vue";

export default {
  name: "order-cases-support",
  mixins: [translateMixin],
  components: {
    SupportCaseDetails,
    SupportCaseDetailsSkeleton,
    DetailsExpander,
  },
  props: {
    supportCase: {
      type: Object,
      required: true,
    },
    scopeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      caseDetails: null,
      expanded: false,
      loadingCaseDetails: true,
      loadCaseError: false,
    };
  },
  computed: {
    reporterFullName() {
      return this.caseDetails.reportingPerson.fullName;
    },

    lastChange() {
      if (this.caseDetails.closedOn) {
        return this.getFormattedDate(this.caseDetails.closedOn);
      }
      return this.getFormattedDate(
        this.caseDetails.updatedOn ? this.caseDetails.updatedOn : this.caseDetails.createdOn
      );
    },
    created() {
      return this.getFormattedDate(this.caseDetails.createdOn);
    },
  },
  methods: {
    getFormattedDate(dateString) {
      let dateParts = dateString.split(" ");
      return `${dateParts[0]} / ${dateParts[1].slice(0, -3)}`;
    },
    expandToggle() {
      this.expanded = !this.expanded;
      if (this.expanded && !this.caseDetails) {
        this.getCaseDetails();
      }
    },
    async getCaseDetails() {
      this.loadingCaseDetails = true;

      try {
        this.caseDetails = await corpSupportCase.CorpCaseService.getCaseDetails({
          acceptLanguage: currentLanguage(),
          caseId: this.supportCase.caseId,
          xAuthScopeId: this.scopeId,
        });
        this.loadingCaseDetails = false;
      } catch (error) {
        this.loadCaseError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/borders/variables";
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";

.details-skeleton {
  width: 100%;
  height: 400px;
}
.support-case {
  display: flex;
  margin-left: $telia-spacing-16;
  border-bottom: $telia-border-width-1 $telia-gray-200 solid;
  padding: $telia-spacing-16 0;
  flex-direction: column;
  .order-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      color: $telia-purple-500;
    }
  }
}

.support-status-wrapper {
  display: flex;
  align-items: center;
  padding-top: $telia-spacing-8;

  > * {
    margin-right: $telia-spacing-8;
    font-size: 1.5rem;
    &:not(:first-of-type):not(:last-of-type):after {
      content: " | ";
    }
  }

  .status {
    display: flex;
    &:before {
      content: "";
      border-radius: 100%;
      width: $telia-spacing-8;
      height: $telia-spacing-8;
      background: $telia-black;
      display: inline-block;
    }
    &.CLOSED:before {
      background: $telia-gray-400;
    }
    &.ONGOING:before {
      background: $telia-blue-300;
    }
    &.RESOLVED:before {
      background: $telia-green-600;
    }
    &.AWAITING_INFO:before {
      background: $telia-orange-500;
    }
    &.DEFAULT:before {
      background: $telia-black;
    }
  }
}
</style>
