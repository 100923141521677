import type { TableManagerColumn } from "@telia/b2x-table/dist/types/components/table-column-manager/table-column-manager";
import { getCompletedColumns, getOngoingColumns, getReceivedColumns } from "./ordersTableColumns";
import { OrderUI } from "@telia/b2b-rest-client/dist/corp-order-history";
import type { Components } from "@teliads/components/dist/types/components";
import { TableHeaderSortableIconType } from "@telia/b2x-table/dist/types/components/table-header-row/table-header-sortable-icon-type";
import { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";
import { translate } from "@telia/b2b-i18n";

type SortingColumnName =
  | "REGISTERED_DATE"
  | "COMPLETION_DATE"
  | "PROMISED_DELIVERY_DATE"
  | "DESCRIPTION"
  | "ORGANISATION_NAME"
  | "REFERENCE"
  | "ORDER_NUMBER"
  | "ORDER_STATUS"
  | "ORDERED_BY"
  | "TYPE"
  | "UPDATED_DATE";
type SortDirection = "ORIGIN" | "ASC" | "DSC";

export type OrderSection = "RECEIVED" | "ONGOING" | "COMPLETED";
function getTranslateTableColumnTitle(title) {
  return translate(`COLUMN.${title}`);
}

interface STATUS_FILTER {
  column: "ORDER_STATUS";
  type: "ONGOING" | "INCOMPLETE" | "RECEIVED" | "CANCELLED" | "DELIVERED";
}
interface ORDER_TYPE_FILTER {
  column: "ORDER_TYPE";
  type: "NEW" | "MOVE" | "CANCELLATION" | "TRANSFER" | "INPORTING" | "CHANGE" | "UNKNOWN";
}

export function getTranslatableColumnValue(filter: STATUS_FILTER | ORDER_TYPE_FILTER): string {
  return translate(`${filter.column}.${filter.type}`);
}

interface ColumnSort {
  column: SortingColumnName;
  sortDirection: SortDirection;
}

export function getColumnTitle(
  translatedTitle: string,
  orderSection: OrderSection
): string | undefined {
  return getColumnsForSection(orderSection).find(
    (c) => getTranslateTableColumnTitle(c.title) === translatedTitle
  )?.title;
}

export function createOrderTableColumns(
  orderSection: OrderSection,
  columnSort: ColumnSort
): TableManagerColumn[] {
  const columns: TableManagerColumn[] = getColumnsForSection(orderSection);
  return columns.map((column) => ({
    ...column,
    title: getTranslateTableColumnTitle(column.title),
    sortable: {
      sortIconName:
        columnSort?.column === column.title ? getSortIcon(columnSort.sortDirection) : "sorter",
    },
  }));
}

function getColumnsForSection(orderSection: OrderSection): TableManagerColumn[] {
  switch (orderSection) {
    case "RECEIVED": {
      return getReceivedColumns();
    }
    case "COMPLETED": {
      return getCompletedColumns();
    }
    case "ONGOING": {
      return getOngoingColumns();
    }
    default: {
      console.error("unknown order section");
      return [];
    }
  }
}

function getSortIcon(sort: SortDirection): TableHeaderSortableIconType {
  switch (sort) {
    case "ASC":
      return "arrow-up";
    case "DSC":
      return "arrow-down";
    default:
      return "sorter";
  }
}

export function createOrderTableRows(orderSection: OrderSection, orders: OrderUI[]): TableEntry[] {
  return orders.map((order, index) => createOrderTableRow(orderSection, order, index));
}

function createOrderTableRow(
  orderSection: OrderSection,
  order: OrderUI,
  rowNumber: number
): TableEntry {
  const {
    orderStatus,
    orderId,
    description,
    orderType,
    promisedDeliveryDate,
    registeredDate,
    organisationName,
    orderedBy,
    reference,
    updatedDate,
    completionDate,
  } = order;
  const commonColumnEntries: TableEntry = [
    {
      variant: getStatusBadgeVariant(orderStatus),
      allyLabel: translate("NOTIFICATION.a11yStatus.information"),
      content: orderStatus ? translate(`ORDER_STATUS.${orderStatus}`) : "",
    },
    {
      content: orderId ?? "",
      rowNumber,
      attrs: {
        variant: "expressive",
      } as Components.TeliaButton,
    },
    description ?? "",
    orderType ? translate(`TYPE.${orderType}`) : "",
  ];

  switch (orderSection) {
    case "COMPLETED": {
      const completedColumnEntries = [
        completionDate ?? "",
        registeredDate ?? "",
        organisationName ?? "",
        orderedBy ?? "",
        reference ?? "",
        updatedDate ?? "",
      ];
      return [...commonColumnEntries, ...completedColumnEntries];
    }
    case "ONGOING": {
      const ongoingColumnEntries = [
        promisedDeliveryDate ?? "",
        registeredDate ?? "",
        organisationName ?? "",
        orderedBy ?? "",
        reference ?? "",
        updatedDate ?? "",
      ];
      return [...commonColumnEntries, ...ongoingColumnEntries];
    }
    case "RECEIVED": {
      const receivedColumnEntries = [
        registeredDate ?? "",
        organisationName ?? "",
        orderedBy ?? "",
        reference ?? "",
      ];
      return [...commonColumnEntries, ...receivedColumnEntries];
    }
    default: {
      return [];
    }
  }
}

function getStatusBadgeVariant(orderStatus: string | undefined) {
  if (orderStatus) {
    const positiveOrderStatuses: string[] = ["DELIVERED", "RESOLVED"];
    const informativeOrderStatuses: string[] = ["RECEIVED", "ONGOING", "AWAITING_INFO"];
    const cautionaryOrderStatuses: string[] = [
      "INCOMPLETE",
      "OUT_OF_STOCK",
      "WAITING_FOR_SIGNING_AGREEMENTS",
    ];
    const warningOrderStatuses: string[] = ["CANCELLED", "CLOSED"];
    const neutralOrderStatuses: string[] = ["UNKNOWN"];

    if (positiveOrderStatuses.indexOf(orderStatus) !== -1) {
      return "positive";
    } else if (informativeOrderStatuses.indexOf(orderStatus) !== -1) {
      return "information";
    } else if (cautionaryOrderStatuses.indexOf(orderStatus) !== -1) {
      return "caution";
    } else if (warningOrderStatuses.indexOf(orderStatus) !== -1) {
      return "warning";
    } else if (neutralOrderStatuses.indexOf(orderStatus) !== -1) {
      return "neutral";
    }
  }

  return "neutral";
}
