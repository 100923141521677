<template>
  <section t-id="order-product-details" class="products-container">
    <div
      v-for="(product, index) in getAccessToItems"
      :key="product.uorOrderItemId"
      :class="getProductClass(product, index)"
    >
      <ProductDetails
        :product="product"
        :orderType="order.orderType"
        :showLink="showLink(product, index)"
      />
    </div>

    <div v-if="getNoAccessToItems.length">
      <div class="icon-wrapper">
        <telia-icon :name="'password-invisible'" size="lg" />
      </div>
      <div>
        <telia-p>
          {{ getNoAccessHead() }},
          {{ t("ORDER_INFO.PRODUCTS_SECTION.MISSING_PERMISSION") }}
        </telia-p>
        <telia-p>
          {{ t("ORDER_INFO.PRODUCTS_SECTION.ORDER_ADMIN") }}
        </telia-p>
        <telia-p>{{ order.orderedBy.name }}</telia-p>
      </div>
    </div>
  </section>
</template>

<script>
import { translateMixin } from "../../locale";
import { formatPhoneNumber } from "@telia/b2x-phone-number-format";
import ProductDetails from "./ProductDetails.vue";
import simcard from "../../../assets/simcard.png";

export default {
  name: "order-details-product",
  components: { ProductDetails },
  mixins: [translateMixin],
  data() {
    return {
      simcard,
      formatPhoneNumber,
    };
  },
  props: {
    order: {
      type: Object,
    },
  },
  computed: {
    getAccessToItems() {
      return this.order.mainOrderItems
        ? this.order.mainOrderItems.filter((item) => item.hasAccess)
        : [];
    },
    getNoAccessToItems() {
      return this.order.mainOrderItems
        ? this.order.mainOrderItems.filter((item) => !item.hasAccess)
        : [];
    },
  },
  methods: {
    shouldHideDividingBorder(item, index) {
      if (!item.parentSubscriptionId) return false;
      return item.parentSubscriptionId === this.getAccessToItems[index + 1]?.parentSubscriptionId;
    },
    showLink(item, index) {
      if (item.parentSubscriptionId && this.getAccessToItems[index + 1]) {
        return item.parentSubscriptionId === this.getAccessToItems[index + 1].parentSubscriptionId;
      } else {
        return false;
      }
    },
    getNoAccessHead() {
      const amountOfHiddenItem = this.getNoAccessToItems.length;
      return amountOfHiddenItem > 1
        ? this.t("ORDER_INFO.PRODUCTS_SECTION.SEVERAL_HIDDEN_PRODUCTS", {
            number: amountOfHiddenItem,
          })
        : this.t("ORDER_INFO.PRODUCTS_SECTION.SINGLE_HIDDEN_PRODUCTS");
    },
    getProductClass(product, index) {
      return this.shouldHideDividingBorder(product, index)
        ? "product-wrapper"
        : "product-wrapper product-wrapper--border-line";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/colors/variables";

.products-container {
  border-top: $telia-border-width-1 $telia-gray-200 solid;
  margin-bottom: $telia-spacing-16;

  .product-wrapper {
    display: flex;
    flex-direction: column;
    padding: $telia-spacing-8 0;
    &--border-line {
      padding: $telia-spacing-16 0;
      border-bottom: $telia-border-width-1 $telia-gray-200 solid;
    }
  }
}
</style>
