<template>
  <div data-test-id="b2b-all-orders" class="page-container">
    <div v-if="status.setup === 'ERROR'" class="page-container__header">
      <telia-grid>
        <section>
          <telia-notification status="error" heading-tag="h4">
            <span slot="heading">
              <telia-visually-hidden>{{
                t("NOTIFICATION.a11yStatus.error")
              }}</telia-visually-hidden>
              <strong>{{ t("NOTIFICATION.general.error.heading") }}</strong>
            </span>
            <span slot="content">
              <telia-p>{{ t("NOTIFICATION.general.error.message") }}</telia-p>
            </span>
          </telia-notification>
        </section>
      </telia-grid>
    </div>
    <b2b-layout
      v-else-if="fetched"
      t-id="b2b-layout"
      :page-title="t('ORDER_HISTORY.HEADER')"
      :heading="t('ORDER_HISTORY.HEADER')"
      :show-organisation-selector="true"
      :has-tabs="true"
      :enable-all-organisations="true"
      @organisationSelected="handleOrganisationChange($event.detail)"
    >
      <div>
        <div>
          <div class="all-orders-categories">
            <OrderCategories
              :selectedCategory="selectedCategory"
              @on-selected-category="setSelectedCategory"
            />
          </div>
          <div v-if="scopeId">
            <section class="section-container">
              <OrderSection
                :sectionType="selectedCategory"
                :scopeId="scopeId"
                :enableToggle="false"
                :collapsed="false"
                :tscid="tscid"
                @orderClicked="handleOrderClick"
              />
            </section>
          </div>
        </div>
        <b2x-drawer
          drawer-id="selected-order-info"
          position="right"
          width="md"
          :heading="t('ORDER_INFO.TITLE')"
          :is-open="isOrderModalOpen"
          @drawerClose="hideInfoModal()"
        >
          <OrderDetailsSkeleton v-show="status.orderDetails === 'LOADING'" />
          <OrderDetails
            v-show="status.orderDetails === 'SUCCESS'"
            :order="selectedOrder"
            :scope-id="scopeId"
            :tscid="organisation.tscid"
            :organizationNumber="organisation.organizationNumber"
            :isOrderModalOpen="isOrderModalOpen"
            @close-modal="hideInfoModal()"
          />
          <telia-notification
            v-show="status.orderDetails === 'ERROR'"
            t-id="error-notification"
            variant="inline"
            status="error"
            heading-tag="h3"
            html-aria-live="polite"
            html-role="alert"
          >
            <span slot="heading">
              <telia-p>{{ t("ERRORS.ORDER_DETAILS") }}</telia-p>
            </span>
          </telia-notification>
        </b2x-drawer>
      </div>
    </b2b-layout>
  </div>
</template>

<script>
import OrderSection from "./components/OrderSection";
import OrderDetails from "./components/details/OrderDetails.vue";
import OrderCategories from "./components/OrderCategories.vue";
import OrderDetailsSkeleton from "./components/details/OrderDetailsSkeleton.vue";

import { mapGetters } from "vuex";
import { translateSetup, translateMixin } from "./locale";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { getOrganizations } from "@telia/b2b-logged-in-service";
import { getOrderDetails } from "./service/order-history-service";

import "@telia/b2x-drawer";
import "@telia/b2x-inline-dropdown";

const state = {
  SUCCESS: "SUCCESS",
  LOADING: "LOADING",
  ERROR: "ERROR",
};

export default {
  name: "b2b-all-orders",
  mixins: [translateMixin],
  components: {
    OrderSection,
    OrderDetails,
    OrderCategories,
    OrderDetailsSkeleton,
  },
  data() {
    return {
      scopeId: null,
      status: {
        setup: state.LOADING,
        orderCategories: {
          ONGOING: state.LOADING,
          RECEIVED: state.LOADING,
          COMPLETED: state.LOADING,
        },
        orderDetails: state.LOADING,
        orderCases: state.LOADING,
        createOrderCaseStatus: "",
      },
      selectedOrder: {},
      orderUid: "",
      isOrderModalOpen: false,
      tscid: "",
      selectedCategory: "ONGOING",
      organisations: [],
      fetched: false,
    };
  },
  async created() {
    await translateSetup(["mybusiness"]);
  },
  async mounted() {
    try {
      this.scopeId = await getScopeIdOrThrow();
      await this.getOrganisations();
    } catch (e) {
      this.status.setup = state.ERROR;
    }

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const orderUid = params["OrderID"];

    if (orderUid) {
      this.handleQueryParameterOrder(orderUid);
    }

    this.fetched = true;
  },
  computed: {
    ...mapGetters(["getOngoingOrders", "getReceivedOrders", "getCompletedOrders"]),

    ordersForSelectedCategory() {
      let ordersForSectionType = [];

      if (this.selectedCategory === "ONGOING") {
        ordersForSectionType = this.getOngoingOrders;
      } else if (this.selectedCategory === "RECEIVED") {
        ordersForSectionType = this.getReceivedOrders;
      } else if (this.selectedCategory === "COMPLETED") {
        ordersForSectionType = this.getCompletedOrders;
      }

      return ordersForSectionType;
    },

    organisation() {
      const organisationName = this.ordersForSelectedCategory.find(
        (order) => order.uid === this.orderUid
      )?.organisationName;

      return this.organisations.find((org) => org.name === organisationName) ?? {};
    },
  },
  methods: {
    handleQueryParameterOrder(uid) {
      if (uid) {
        this.handleOrderClick(uid);
      }
    },
    async getOrganisations() {
      this.organisations = await getOrganizations();
      if (this.organisations.length === 1) {
        this.tscid = this.organisations[0]?.tscId;
      }
    },
    orderCategory(orderStatus) {
      switch (orderStatus) {
        case "ONGOING":
        case "INCOMPLETE":
          return "ONGOING";
        case "RECEIVED":
          return "RECEIVED";
        case "CANCELLED":
        case "DELIVERED":
          return "COMPLETED";
        default:
          return orderStatus;
      }
    },
    async handleOrderClick(orderUid) {
      this.orderUid = orderUid;
      this.status.orderDetails = state.LOADING;
      this.openInfoModal();
      try {
        this.selectedOrder = await getOrderDetails(this.scopeId, orderUid);
        this.status.orderDetails = state.SUCCESS;
      } catch (e) {
        this.status.orderDetails = state.ERROR;
      }
    },
    openInfoModal() {
      this.isOrderModalOpen = true;
    },
    hideInfoModal() {
      this.isOrderModalOpen = false;
      this.selectedOrder = {};
    },
    handleOrganisationChange(tscid) {
      if (tscid === "ALL") {
        this.setOrganisation();
        return;
      }
      this.setOrganisation(tscid);
    },
    setOrganisation(value = "") {
      this.tscid = value;
    },
    setSelectedCategory(value) {
      this.selectedCategory = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.page-container {
  margin-bottom: $telia-spacing-64;
  &__header {
    background-color: $telia-gray-50;
    padding: $telia-spacing-32 0 $telia-spacing-64;
  }
}
.section-container {
  margin-top: $telia-spacing-12;
}
.org-selector {
  padding: $telia-spacing-12 0;
}
.all-orders-categories {
  margin-top: -4.2rem;
}

.page-title-wrapper {
  padding: $telia-spacing-32 0;
}
.notification-container {
  padding-top: $telia-spacing-24;
  max-width: 64rem;
}
.technical-issues {
  margin-top: $telia-spacing-24;
  max-width: fit-content;
}
</style>
