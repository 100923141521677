import { corpOrderHistory } from "@telia/b2b-rest-client";
import { OrderStatusUI } from "@telia/b2b-rest-client/dist/corp-order-history";

export function getOrderHistory(scopeId: string, status: OrderStatusUI | OrderStatusUI[]) {
  return corpOrderHistory.PublicControllerService.getOrderHistoryUsingGet(
    scopeId,
    undefined,
    undefined,
    status
  );
}

export function getOrderDetails(scopeId: string, orderId: string) {
  return corpOrderHistory.PublicControllerService.getOrderDetailsUsingGet(scopeId, orderId);
}
