<template>
  <div class="status-wrapper">
    <div :class="isActive([STATUS.RECEIVED])">
      <telia-icon name="arrow-down" size="lg"></telia-icon>
      <telia-p>{{ t(`ORDER_STATUS.${STATUS.RECEIVED}`) }}</telia-p>
    </div>

    <div :class="isActive([STATUS.ONGOING, STATUS.INCOMPLETE])">
      <telia-icon
        :name="order.orderStatus === STATUS.INCOMPLETE ? 'alert' : 'logout'"
        size="lg"
      />
      <telia-p>{{
        t(
          `ORDER_STATUS.${
            order.orderStatus === STATUS.INCOMPLETE ? STATUS.INCOMPLETE : STATUS.ONGOING
          }`
        )
      }}</telia-p>
    </div>

    <div :class="isActive([STATUS.DELIVERED, STATUS.CANCELLED, STATUS.CLOSED])">
      <telia-icon
        :name="order.orderStatus === STATUS.CANCELLED ? 'close-circle' : 'check-circle'"
        size="lg"
      />
      <telia-p>{{
        t(
          `ORDER_STATUS.${
            order.orderStatus === STATUS.CANCELLED ? STATUS.CANCELLED : STATUS.DELIVERED
          }`
        )
      }}</telia-p>
    </div>
  </div>
</template>

<script>
import { translateMixin } from "../../locale";

const STATUS = {
  RECEIVED: "RECEIVED",
  ONGOING: "ONGOING",
  INCOMPLETE: "INCOMPLETE",
  DELIVERED: "DELIVERED",
  CANCELLED: "CANCELLED",
  CLOSED: "CLOSED",
};

export default {
  name: "order-details-status",
  mixins: [translateMixin],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      STATUS,
    };
  },
  methods: {
    isActive(defaultStatuses) {
      return defaultStatuses.includes(this.order.orderStatus) ? "active" : "inactive";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.status-wrapper {
  margin-top: $telia-spacing-32;
  margin-bottom: $telia-spacing-32;
  display: flex;
  justify-content: center;

  > div {
    position: relative;
    text-align: center;
    display: inline-block;
    width: 100px;

    &.inactive {
      color: #cccccc;
    }
    &:not(:last-child) {
      margin-right: $telia-spacing-64;
      &::after {
        content: "";
        width: 60px;
        height: 2px;
        background: #ccc;
        position: absolute;
        top: 16px;
        right: calc(-60%);
      }
    }
  }
}
</style>
