<template>
  <div class="case-details">
    <telia-row class="details">
      <telia-col width="12">
        <telia-skeleton />
      </telia-col>
      <telia-col width="12">
        <telia-skeleton class="reporter" />
      </telia-col>
      <telia-col width="12">
        <telia-skeleton />
      </telia-col>
      <telia-col width="12">
        <telia-skeleton />
      </telia-col>
    </telia-row>
  </div>
</template>

<script>
export default {
  name: "support-case-details-skeleton",
};
</script>
<style lang="scss" scoped>
@import "@teliads/components/foundations/borders/variables";
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";

.details {
  > * {
    margin-top: $telia-spacing-8;
  }

  * > telia-skeleton {
    width: 100%;
    height: 40px;
  }

  .reporter {
    height: 60px;
  }
}
</style>
