<template>
  <telia-grid class="telia-grid container" :class="[{ 'wide-table': isWideTable }]">
    <section class="head-section">
      <div class="icon-title">
        <telia-heading tag="h3" variant="title-200">
          {{
            t(`ORDER_HISTORY.${sectionType}.TITLE_WITH_COUNT`, {
              count: ordersForSectionType.length,
            })
          }}
        </telia-heading>
      </div>
      <telia-p>
        {{ t(`ORDER_HISTORY.${sectionType}.SUBTITLE`) }}
      </telia-p>
    </section>
    <section v-if="status === 'ERROR'">
      <telia-notification
        v-show="status === 'ERROR'"
        variant="inline"
        status="warning"
        heading-tag="h3"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-p>{{ t("ERRORS.ORDER_HISTORY") }}</telia-p>
        </span>
      </telia-notification>
    </section>
    <section v-if="status !== 'ERROR'">
      <b2x-controlled-table
        t-id="order-table"
        :key="sectionType"
        :ref="'table'"
        :column-filter-label="t('mybusiness.columnManager')"
        :reset-column-label="t('mybusiness.resetColumns')"
        :columns="JSON.stringify(columns)"
        :data="JSON.stringify(rowsForPage)"
        :message="noSubscriptionMessage"
        :is-loading="status === 'LOADING' || tableStatus === 'LOADING'"
        :is-wide-table="isWideTable"
        :show-filter-row="hasOrders"
        :show-expand-button="hasOrders"
        :show-clear-filters-button="hasOrders"
        :clear-filters-button-disabled="isClearFiltersButtonDisabled"
        @filterColumnInput="onFilterColumnInput"
        @clearAllFilters="onClearAllFilters"
        @buttonTextClicked="handleButtonClick"
        @columnClick="onColumnClick"
      ></b2x-controlled-table>
      <b2x-paginator
        v-if="hasOrders"
        :list-length="filteredOrders.length"
        :page-sizes="JSON.stringify(pageSizes)"
        :default-page-size="defaultPageSize"
        @paginationChange="handlePageChange"
      />
    </section>
  </telia-grid>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { translateMixin } from "../locale";
import { getOrderHistory } from "../service/order-history-service";
import {
  createOrderTableColumns,
  createOrderTableRows,
  getColumnTitle,
} from "./order-table/order-table-manager";
import { ColumnOrderProperty } from "./order-table/ordersTableColumns";
import "@telia/b2x-table";
import "@telia/b2x-paginator";

const state = {
  SUCCESS: "SUCCESS",
  LOADING: "LOADING",
  ERROR: "ERROR",
};

export default {
  name: "all-orders-section",
  mixins: [translateMixin],
  props: {
    scopeId: {
      type: String,
      required: true,
    },
    tscid: {
      type: String,
      default: "",
    },
    sectionType: {
      type: String,
      required: true,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    enableToggle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    status: "LOADING",
    tableStatus: "LOADING",
    selectedSorting: "UPDATED_DATE",
    sortDirection: "DSC",
    isWideTable: false,
    tableCollapsed: false,
    defaultPageSize: 5,
    pageSizes: [5, 15, 25],
    page: 1,
    pageSize: 5,
    columnFilter: {},
  }),
  async mounted() {
    await this.fetchOrders();
    addEventListener("controlledTableExpandClicked", this.toggleWideTable);
  },
  destroyed() {
    removeEventListener("controlledTableExpandClicked", this.toggleWideTable);
  },
  watch: {
    sectionType() {
      this.columnFilter = {};
      this.page = 1;
      this.fetchOrders();
    },
  },

  computed: {
    ...mapGetters([
      "hasOngoingOrders",
      "hasReceivedOrders",
      "hasCompletedOrders",
      "getOngoingOrders",
      "getReceivedOrders",
      "getCompletedOrders",
    ]),
    ordersForSectionType() {
      let ordersForSectionType = [];

      if (this.sectionType === "ONGOING") {
        ordersForSectionType = this.getOngoingOrders;
      } else if (this.sectionType === "RECEIVED") {
        ordersForSectionType = this.getReceivedOrders;
      } else if (this.sectionType === "COMPLETED") {
        ordersForSectionType = this.getCompletedOrders;
      }

      ordersForSectionType = this.tscid
        ? ordersForSectionType.filter((r) => r.tscId === this.tscid)
        : ordersForSectionType;

      return ordersForSectionType;
    },
    hasOrders() {
      if (this.sectionType === "ONGOING") {
        return this.hasOngoingOrders;
      } else if (this.sectionType === "RECEIVED") {
        return this.hasReceivedOrders;
      } else if (this.sectionType === "COMPLETED") {
        return this.hasCompletedOrders;
      }

      return false;
    },
    columns() {
      return createOrderTableColumns(this.sectionType, {
        column: this.selectedSorting,
        sortDirection: this.sortDirection,
      });
    },
    filteredOrders() {
      return this.sortOrders(this.searchOrders());
    },
    rowsForPage() {
      const ordersForPage = this.getOrdersForPage(this.filteredOrders, this.page, this.pageSize);
      return createOrderTableRows(this.sectionType, ordersForPage);
    },
    isClearFiltersButtonDisabled() {
      return Object.keys(this.columnFilter).length === 0;
    },
    noSubscriptionMessage() {
      return !this.hasOrders ? this.t("ORDER_HISTORY.NO_ORDERS") : "";
    },
  },
  methods: {
    ...mapActions(["setOngoingOrders", "setReceivedOrders", "setCompletedOrders"]),

    getOrdersForPage(orders, page, pageSize) {
      this.tableStatus = state.LOADING;
      const ordersForPage = orders.slice((page - 1) * pageSize, page * pageSize);
      this.tableStatus = state.SUCCESS;
      return ordersForPage;
    },

    async fetchOrders() {
      if (this.sectionType === "ONGOING") {
        if (!this.hasOngoingOrders) {
          try {
            this.status = state.LOADING;
            const ongoingOrders = await getOrderHistory(this.scopeId, ["ONGOING", "INCOMPLETE"]);
            this.setOngoingOrders(ongoingOrders);
            this.status = state.SUCCESS;
          } catch (err) {
            this.status = state.ERROR;
          }
        } else {
          this.status = state.SUCCESS;
        }
      } else if (this.sectionType === "RECEIVED") {
        if (!this.hasReceivedOrders) {
          try {
            this.status = state.LOADING;
            const receivedOrders = await getOrderHistory(this.scopeId, ["RECEIVED"]);
            this.setReceivedOrders(receivedOrders);
            this.status = state.SUCCESS;
          } catch (err) {
            this.status = state.ERROR;
          }
        } else {
          this.status = state.SUCCESS;
        }
      } else if (this.sectionType === "COMPLETED") {
        if (!this.hasCompletedOrders) {
          try {
            this.status = state.LOADING;
            const completedOrders = await getOrderHistory(this.scopeId, ["CANCELLED", "DELIVERED"]);
            this.setCompletedOrders(completedOrders);
            this.status = state.SUCCESS;
          } catch (err) {
            this.status = state.ERROR;
          }
        } else {
          this.status = state.SUCCESS;
        }
      }
    },
    handlePageChange(event) {
      const { page, pageSize } = event.detail;
      this.page = page;
      this.pageSize = pageSize;
    },
    handleButtonClick(event) {
      const orderNumber = event.detail.buttonTextContent;
      const order = this.ordersForSectionType.find((x) => x.orderId === orderNumber);
      this.$emit("orderClicked", order.uid);
    },
    onFilterColumnInput(event) {
      const { column, value } = event.detail;
      const titleKey = getColumnTitle(column, this.sectionType);

      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.page = 1;

        if (value?.length > 0) {
          this.columnFilter = {
            ...this.columnFilter,
            [titleKey]: value,
          };
        } else {
          const columnFilter = { ...this.columnFilter };
          delete columnFilter[titleKey];
          this.columnFilter = columnFilter;
        }
      });
    },
    sortOrders(items) {
      if (!this.selectedSorting) {
        return items;
      }
      const propName = ColumnOrderProperty[this.selectedSorting];
      items.sort((a, b) => b[propName]?.localeCompare(a[propName]));
      if (this.sortDirection === "ASC") {
        items.reverse();
      }
      return items;
    },
    searchOrders() {
      let filteredOrders = this.ordersForSectionType;
      Object.keys(this.columnFilter).forEach((columnKey) => {
        const searchValue = this.columnFilter[columnKey].toLowerCase();
        const propName = ColumnOrderProperty[columnKey];
        if (columnKey === "TYPE" || columnKey === "ORDER_STATUS") {
          filteredOrders = filteredOrders.filter((r) => {
            return r[propName]?.toLowerCase() === searchValue.toLowerCase();
          });
        } else {
          filteredOrders = filteredOrders.filter((r) => {
            return r[propName]?.toLowerCase().includes(searchValue);
          });
        }
      });
      return filteredOrders;
    },
    onClearAllFilters() {
      this.columnFilter = {};
    },
    onColumnClick(event) {
      const columnTitleKey = getColumnTitle(event.detail, this.sectionType);
      this.setSort(columnTitleKey);
    },
    setSort(selectedSorting) {
      if (this.selectedSorting === selectedSorting) {
        this.sortDirection = this.sortDirection === "ASC" ? "DSC" : "ASC";
      } else {
        this.sortDirection = "DSC";
      }
      this.selectedSorting = selectedSorting;
    },
    toggleWideTable() {
      this.isWideTable = !this.isWideTable;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@telia/b2x-table/src/components/controlled-table-actions/controlled-table-actions";
@include wide-table;

.head-section {
  margin-bottom: $telia-spacing-12;
}
</style>
