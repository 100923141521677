import { createStore } from "vuex";

function defaultState() {
  return {
    ongoingOrders: [],
    receivedOrders: [],
    completedOrders: [],
  };
}

export const store = createStore({
  state: defaultState(),

  getters: {
    getOngoingOrders: (state) => state.ongoingOrders,
    getReceivedOrders: (state) => state.receivedOrders,
    getCompletedOrders: (state) => state.completedOrders,
    hasOngoingOrders: (state) => state.ongoingOrders.length > 0,
    hasReceivedOrders: (state) => state.receivedOrders.length > 0,
    hasCompletedOrders: (state) => state.completedOrders.length > 0,
  },

  actions: {
    setOngoingOrders: ({ commit }, orders = []) => {
      commit("SET_ONGOING_ORDERS", orders);
    },

    setReceivedOrders: ({ commit }, orders = []) => {
      commit("SET_RECEIVED_ORDERS", orders);
    },

    setCompletedOrders: ({ commit }, orders = []) => {
      commit("SET_COMPLETED_ORDERS", orders);
    },

    resetState: ({ commit }) => {
      commit("RESET_STATE");
    },
  },

  mutations: {
    SET_ONGOING_ORDERS(state, orders = []) {
      state.ongoingOrders = orders;
    },

    SET_RECEIVED_ORDERS(state, orders = []) {
      state.receivedOrders = orders;
    },

    SET_COMPLETED_ORDERS(state, orders = []) {
      state.completedOrders = orders;
    },

    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },
  },
});
