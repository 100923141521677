<template>
  <div class="case-details">
    <telia-row class="details">
      <telia-col width="12">
        <telia-p>
          {{ description }}
        </telia-p>
      </telia-col>
      <telia-col width="12">
        <telia-heading tag="h4" variant="subsection-100">{{
          t("SUPPORT.reportedBy")
        }}</telia-heading>
        <telia-p>{{ reporterName }}</telia-p>
        <telia-p>{{ reporterEmail }}</telia-p>
        <telia-p>{{ reporterPhoneNumber }}</telia-p>
      </telia-col>
      <telia-col width="12">
        <telia-heading tag="h4" variant="subsection-100">{{ t("SUPPORT.created") }}</telia-heading>
        <telia-p>{{ createdOn }}</telia-p>
      </telia-col>
      <telia-col width="12">
        <telia-heading tag="h4" variant="subsection-100">{{
          t("SUPPORT.updatedClosed")
        }}</telia-heading>
        <telia-p>{{ lastChange }}</telia-p>
      </telia-col>
      <telia-col width="12">
        <telia-p>{{ t("SUPPORT.link.info") }}</telia-p>
        <telia-link :href="`/foretag/mybusiness/${scopeId}/support/arenden/detaljer/${caseId}`">
          <telia-p>{{ t("SUPPORT.link.link") }}</telia-p>
        </telia-link>
      </telia-col>
    </telia-row>
    <telia-row>
      <slot></slot>
    </telia-row>
  </div>
</template>

<script>
import { translateMixin } from "../../../locale";
export default {
  name: "support-case-details",
  mixins: [translateMixin],
  props: {
    description: {
      type: String,
    },
    reporterName: {
      type: String,
    },
    reporterEmail: {
      type: String,
    },
    reporterPhoneNumber: {
      type: String,
    },
    createdOn: {
      type: String,
    },
    lastChange: {
      type: String,
    },
    caseId: {
      type: String,
    },
    scopeId: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
.details {
  > * {
    margin-top: $telia-spacing-8;
  }
}
</style>
