import { translate as t } from "@telia/b2b-i18n";

type MappedField = {
  label: string;
  value: string;
  formatter?: (value: string) => string;
};

const shippingAddress = (value) => {
  if (!value) return "";

  const street = value.street ?? "";
  const zipCode = value.zipCode ?? "";
  const city = value.city ?? "";

  if (!street && !zipCode && !city) return "";
  return `${street} ${zipCode} ${city}`;
};

const billingAccount = (value) => {
  return value ? shippingAddress(value.address) : "";
};

const billingAddress = (value) => {
  return value ? value.number : "";
};

const orderedBy = (value) => {
  if (!value) return "";

  const name = value.name ?? "";
  const phone = value.phone ?? "";
  const mail = value.mail ?? "";

  if (!name && !phone && !mail) return "";
  return `${name} ${phone} ${mail}`;
};

const orderType = (value) => {
  if (!value || value === "UNKNOWN") return "";
  return t(`ORDER_TYPE.${value}`);
};

const config: MappedField[] = [
  {
    label: "COLUMN.SHIPPING_ADDRESS",
    value: "shippingAddress",
    formatter: shippingAddress,
  },
  {
    label: "COLUMN.REGISTERED_DATE",
    value: "registeredDate",
  },
  {
    label: "COLUMN.PROMISED_DELIVERY_DATE",
    value: "promisedDeliveryDate",
  },
  {
    label: "COLUMN.COMPLETION_DATE",
    value: "completionDate",
  },
  {
    label: "COLUMN.TYPE",
    value: "orderType",
    formatter: orderType,
  },
  {
    label: "COLUMN.ORDERED_BY",
    value: "orderedBy",
    formatter: orderedBy,
  },
  {
    label: "COLUMN.ORGANISATION_NAME",
    value: "organisationName",
  },
  {
    label: "COLUMN.BILLING_ACCOUNT",
    value: "billingAccount",
    formatter: billingAddress,
  },
  {
    label: "COLUMN.BILLING_ADDRESS",
    value: "billingAccount",
    formatter: billingAccount,
  },
];

export const getMappedFields = (data: any): MappedField[] =>
  config
    .map((x: MappedField, index: number) => ({
      ...x,
      id: index,
      label: t(x.label),
      value: x.formatter ? x.formatter(data[x.value ?? ""]) : data[x.value ?? ""],
    }))
    .filter((x) => x.value);
