<template>
  <div class="all-order-categories">
    <telia-grid>
      <telia-tab
        variant="contained-on-negative"
        full-width
        class="all-orders-categories__tabs"
        @vocaTabChange="onTabChange"
      >
        <telia-tab-content
          tab-id="ONGOING"
          :active="isTabActive(CATEGORY.ONGOING)"
          :name="t('ORDER_HISTORY.ONGOING.TITLE')"
        />
        <telia-tab-content
          tab-id="RECEIVED"
          :active="isTabActive(CATEGORY.RECEIVED)"
          :name="t('ORDER_HISTORY.RECEIVED.TITLE')"
        />
        <telia-tab-content
          tab-id="COMPLETED"
          :active="isTabActive(CATEGORY.COMPLETED)"
          :name="t('ORDER_HISTORY.COMPLETED.TITLE')"
        />
      </telia-tab>
    </telia-grid>
  </div>
</template>

<script>
import { translateMixin } from "../locale";

const CATEGORY = {
  ONGOING: "ONGOING",
  COMPLETED: "COMPLETED",
  RECEIVED: "RECEIVED",
};

export default {
  name: "all-orders-categories",
  mixins: [translateMixin],
  props: {
    selectedCategory: { type: String },
  },
  data() {
    return {
      CATEGORY,
    };
  },
  methods: {
    onTabChange(event) {
      const category = event.detail.value;
      this.$emit("on-selected-category", category);
    },
    isTabActive(value) {
      return this.selectedCategory === value;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.skeleton-tabs {
  height: $telia-spacing-32;
  margin-bottom: $telia-spacing-48;
  display: flex;
  justify-content: space-between;
}
.skeleton-tab {
  height: 100%;
  width: 100%;
  margin: 0 $telia-spacing-4;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
</style>
